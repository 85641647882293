import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { aboutFields } from "../../metadata";
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3,
    fontSize: "20px",
    color: "#777777",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  container: {
    marginTop: theme.spacing.unit * 7,
    marginBottom: theme.spacing.unit * 10
  }
});

const About = ({ classes, location }) => (
  <Layout location={location}>
    <React.Fragment>
      <TabBar fields={aboutFields} />
      <div className={classes.root}>
        <div className={classes.subheading}>
          Software engineering companies are only as good as their people.
        </div>
        <div className={classes.article}>Our people are good.</div>
        <div className={classes.container}>
          <div className={classes.paragraph}>
            Primoko is a software engineering firm whose founders bring 50+
            years of software engineering experience to the table to tackle your
            most challenging engineering projects.
          </div>
          <div className={classes.paragraph}>
            Give us a bull; we’ll bring our cape.
          </div>
          <div className={classes.paragraph}>
            Give us a runaway train; we’ll bring our catch engine.
          </div>
          <div className={classes.paragraph}>
            Give us your most difficult engineering obstacle; we’ll bring an
            engineering expert with the skills and tools to face it.
          </div>
        </div>
      </div>
    </React.Fragment>
  </Layout>
);

export default withStyles(styles)(About);
